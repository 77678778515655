/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import { Languages } from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-250px w-lg-325px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/150-25.jpg')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              Test
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              Test@gmil.com
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='row g-0'>
        <div className="col-6">
          <Link to="/account/profile" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-success mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor" />
                <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">Profile Setting</span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/myoverview" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              {/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M22 12C22 13.8 21.5 15.5 20.7 17L14.9 7H20.7C21.5 8.5 22 10.2 22 12ZM3.3 7L6.2 12L12 2C8.3 2 5.1 4 3.3 7ZM3.3 17C5 20 8.3 22 12 22L14.9 17H3.3Z" fill="currentColor" />
                <path d="M20.7 7H9.2L12.1 2C15.7 2 18.9 4 20.7 7ZM3.3 7C2.4 8.5 2 10.2 2 12C2 13.8 2.5 15.5 3.3 17H9.10001L3.3 7ZM17.8 12L12 22C15.7 22 18.9 20 20.7 17L17.8 12Z" fill="currentColor" />
              </svg>
              {/*end::Svg Icon*/}
              <span className="fs-5 fw-semibold text-gray-800 mb-0">
                Over View
              </span>
            </span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/orders/list" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z" fill="currentColor" />
                <path d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">Order List</span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/orders/cancelList" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/communication/com010.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={5} fill="currentColor" />
                <rect x={7} y="15.3137" width={12} height={2} rx={1} transform="rotate(-45 7 15.3137)" fill="currentColor" />
                <rect x="8.41422" y={7} width={12} height={2} rx={1} transform="rotate(45 8.41422 7)" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">Cancel List </span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/orders/activeList" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor" />
                <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">Active List</span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/orders/approvedList" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/communication/com010.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 6.30005C20.5 5.30005 19.9 5.19998 18.7 5.09998C17.5 4.99998 14.5 5 11.9 5C9.29999 5 6.29998 4.99998 5.09998 5.09998C3.89998 5.19998 3.29999 5.30005 2.79999 6.30005C2.19999 7.30005 2 8.90002 2 11.9C2 14.8 2.29999 16.5 2.79999 17.5C3.29999 18.5 3.89998 18.6001 5.09998 18.7001C6.29998 18.8001 9.29999 18.8 11.9 18.8C14.5 18.8 17.5 18.8001 18.7 18.7001C19.9 18.6001 20.5 18.4 21 17.5C21.6 16.5 21.8 14.9 21.8 11.9C21.8 9.00002 21.5 7.30005 21 6.30005ZM9.89999 15.7001V8.20007L14.5 11C15.3 11.5 15.3 12.5 14.5 13L9.89999 15.7001Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">Approved List</span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/myFavorites" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/abstract/abs042.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0"> My Favorites </span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/myusers" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                <path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor" />
                <rect x={7} y={6} width={4} height={4} rx={2} fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">My Users</span>
          </Link>
        </div>

        <div className="col-6">
          <Link to="/account/myBillings" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/finance/fin009.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-primary mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="currentColor" />
                <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">My Billing </span>
          </Link>
        </div>



        <div className="col-6">
          <Link to="/" className="d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom">
            {/*begin::Svg Icon | path: icons/duotune/communication/com010.svg*/}
            <span className="svg-icon svg-icon-3x svg-icon-danger mb-2">
              <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M22 8.20267V15.7027C22 19.1027 19.2 22.0026 15.7 22.0026H8.2C4.8 22.0026 2 19.2027 2 15.7027V8.20267C2 4.80267 4.8 2.0026 8.2 2.0026H15.7C19.2 1.9026 22 4.70267 22 8.20267ZM12 7.30265C9.5 7.30265 7.39999 9.40262 7.39999 11.9026C7.39999 14.4026 9.5 16.5026 12 16.5026C14.5 16.5026 16.6 14.4026 16.6 11.9026C16.6 9.30262 14.5 7.30265 12 7.30265ZM17.9 5.0026C17.3 5.0026 16.9 5.4026 16.9 6.0026C16.9 6.6026 17.3 7.0026 17.9 7.0026C18.5 7.0026 18.9 6.6026 18.9 6.0026C18.9 5.5026 18.4 5.0026 17.9 5.0026Z" fill="currentColor" />
                <path d="M12 17.5026C8.9 17.5026 6.39999 15.0026 6.39999 11.9026C6.39999 8.80259 8.9 6.30261 12 6.30261C15.1 6.30261 17.6 8.80259 17.6 11.9026C17.6 15.0026 15.1 17.5026 12 17.5026ZM12 8.30261C10 8.30261 8.39999 9.90259 8.39999 11.9026C8.39999 13.9026 10 15.5026 12 15.5026C14 15.5026 15.6 13.9026 15.6 11.9026C15.6 9.90259 14 8.30261 12 8.30261Z" fill="currentColor" />
              </svg>
            </span>
            {/*end::Svg Icon*/}
            <span className="fs-5 fw-semibold text-gray-800 mb-0">Logout</span>
          </Link>
        </div>

        <div className='col-6'>
          <a
            href='#'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-bottom'
          >
            <KTSVG
              path='/media/icons/duotune/communication/com010.svg'
              className='svg-icon-3x svg-icon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Administration</span>
            <span className='fs-7 text-gray-400'>Console</span>
          </a>
        </div>

        <div className='col-6'>
          <a href='#' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end'>
            <KTSVG
              path='/media/icons/duotune/abstract/abs042.svg'
              className='svg-icon-3x svg-icon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Projects</span>
            <span className='fs-7 text-gray-400'>Pending Tasks</span>
          </a>
        </div>

        <div className='col-6'>
          <a href='#' className='d-flex flex-column flex-center h-100 p-6 bg-hover-light'>
            <KTSVG
              path='/media/icons/duotune/finance/fin006.svg'
              className='svg-icon-3x svg-icon-primary mb-2'
            />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Customers</span>
            <span className='fs-7 text-gray-400'>Latest cases</span>
          </a>
        </div>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }

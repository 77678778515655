import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))

  const MainPage = lazy(() => import('../pages/mainpage/mainPage'))
  const MainCategoryPage = lazy(() => import('../pages/mainCategory/mainCategory'))
  const SubCategoryPage = lazy(() => import('../pages/subCategoey/subCategory'))
  const ProductPage = lazy(() => import('../pages/product/product'))
  const CartPage = lazy(() => import('../pages/cart/cart'))
  const CheckOutPage = lazy(() => import('../pages/checkout/checkout'))
  const MYProfilePage = lazy(() => import(('../pages/profile/profile')));

  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const MainPage = lazy(() => import('../pages/mainpage/mainPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/home' component={MainPage} />
        <Route path='/category' component={MainCategoryPage} />
        <Route path='/sucategory' component={SubCategoryPage} />
        <Route path='/product' component={ProductPage} />
        <Route path='/cart' component={CartPage} />
        <Route path='/checkout' component={CheckOutPage} />
        <Route path='/account' component={MYProfilePage} />

        {/* <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        <Redirect from='/auth' to='/home' />
        <Redirect exact from='/' to='/home' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}

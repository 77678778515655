import axios from 'axios';
import { getCookie } from "typescript-cookie";

let access_token = getCookie('access_token');
// console.log(access_token);
if(!access_token){
    access_token = '';
}
export default axios.create({
  baseURL: 'https://arkwrxclientapi.cafemobility.com/',
//   baseURL: "http://localhost:3001/",
  headers: {
    'Content-type': 'application/json',
    'x-access-token' : access_token,
  },
})

export const baseUrl2 = 'https://arkwrxapi.cafemobility.com/'
// export const baseUrl = "http://localhost:3001/";
export const baseUrl = 'https://arkwrxclientapi.cafemobility.com/'
// export const baseUrl2 = "http://localhost:3002/";
